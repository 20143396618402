<script>
	import GlobalVue from '@helper/Global.vue';
	import Gen from '@helper/Gen';
	export default {
		extends: GlobalVue,
		data() {
			return {
				sosial_media:{},
				data:{},
				cmp_name:{},
				cmp_phone:{},
				cmp_address:{},
				cmp_email:{},
			}
		},
		computed:{
			yearcopy(){
				let year = 2020
				let cyear = (new Date).getFullYear()
				return year == cyear ? year : year+" - "+cyear
			}
		},
		mounted() {
			this.getContent()
		},
		methods: {
			getContent(){
				Gen.apirest("/content-footer",{},(err,resp)=>{
					if(err) console.log(err)
					this.data = resp.data.static_footer
					this.sosial_media = resp.data.sosial_media
					this.cmp_name = resp.data.cmp_name
					this.cmp_phone = resp.data.cmp_phone
					this.cmp_address = resp.data.cmp_address
					this.cmp_email = resp.data.cmp_email
				})
			},
			sortCity(){
				this.$router.push({
                    path: '/about-us',
                    query: {
                      section: 'city'
                    }
                })
			}
		}
	};
</script>
<template>

	<footer id="footer" class="dark sticky-footer" style="background-color: #fff;margin-top: 0px !important;">
		<div class="container">
			<div class="footer-widgets-wrap clearfix">
				<div class="row clearfix">
					<div class="col-lg-4">
						<div class="widget clearfix mb-4 mb-lg-0">
							<div class="row clearfix">
								<div class="col-lg-11  clearfix" style="color:#888;">
									<VImg :src="uploader(data.asc_img1)" alt="Dimatique"
										title="Dimatique" style="display: block;"></VImg>
									<p v-html="data.description"></p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-8">
						<div class="row clearfix">
							<div class="col-md-3 col-6">
								<div class="widget widget_links app_landing_widget_link clearfix">
									<h3>{{web.lbl_about}}</h3>
									<ul class="pt-md-3">
										<li>
											<router-link :to="{name:'About'}">{{ web.mn_about }}</router-link>
										</li>
										<li><a href="javascript:;" @click="sortCity">{{web.mn_dimatique_cities}}</a></li>
										<li>
											<router-link :to="{name:'Faq'}">{{ web.mn_faq }}</router-link>
										</li>
										<li>
											<router-link :to="{name:'Blog'}">{{ web.mn_blog }}</router-link>
										</li>
										<li>
											<router-link :to="{name:'Event'}">{{ web.mn_event }}</router-link>
										</li>
									</ul>
								</div>
								<!-- <div class="widget widget_links app_landing_widget_link clearfix">
									<h3>Menu</h3>
									<ul class="pt-md-3">
										<li>
											<router-link :to="{name:'News'}">{{ web.mn_news }}</router-link>
										</li>
									</ul>
								</div> -->
							</div>
							<div class="col-md-4 col-6">
								<div class="widget widget_links app_landing_widget_link clearfix">
									<h3>{{web.lbl_partner_with_us}}</h3>
									<ul class="pt-md-3">
										<li>
											<router-link :to="{name:'Package'}">{{web.mn_special_event_service}}
											</router-link>
										</li>
										<li>
											<router-link :to="{name:'Affiliate'}">{{web.mn_become_an_affiliate}}
											</router-link>
										</li>
										<li>
											<router-link :to="{name:'Wineries'}">{{web.mn_become_an_wineries}}
											</router-link>
										</li>
									</ul>
								</div>
							</div>
							<!-- <div class="col-md-3 col-6">
							</div> -->
							<div class="col-md-5">
								<div class="widget widget_links app_landing_widget_link clearfix">
									<h3 class="mb-2 mb-md-0">{{web.lbl_address}}</h3>
									<div class="address_footer pt-1 pt-md-0 mt-0 mt-md-3">
										<h3 class="">{{cmp_name.aws_val}}</h3>
										<p v-html="cmp_address.aws_val"></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="copyrights" class="nobg notoppadding">
			<div class="container clearfix">
				<div class="col_full mb-0 text-center social-icons">
					<a :href="v.mas_val" target="_blank" v-for="(v,k) in sosial_media" :key="k" :class="'social-icon si-dark  si-small si-borderless si-rounded si-'+v.mas_title">
						<i :class="'icon-'+v.mas_title"></i>
						<i :class="'icon-'+v.mas_title"></i>
					</a>
				</div>
				<div class="col_full text-center">
					<p>Copyrights &copy; {{yearcopy}} All Rights Reserved by dimatique.</p>
					<div class="copyright-links">
						<router-link :to="{name:'Toc'}">{{web.lbl_toc}}</router-link> /
						<router-link :to="{name:'PrivacyPolicy'}">{{web.lbl_privacy}}</router-link> /
						<router-link :to="{name:'CookiePolicy'}">{{web.lbl_cookie}}</router-link> /
						<router-link :to="{name:'Contact'}">{{ web.mn_contact }}</router-link>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>